import React from "react"

import classNames from "classnames"

import { TableSimple } from "@nerdwallet/currency"
import PropTypes from "prop-types"

import { EntityCell } from "@src/components/Table"
import LenderCTA from "@src/components/LenderCTA"
import Tooltip from "@src/components/Tooltip"

import { REVIEW_CTA_TYPE, SUMMARY_CTA_TYPE } from "@src/lib/constants"

import useFeatureFlag from "@src/hooks/useFeatureFlag"

import styles from "./LendersTable.module.less"

const ReviewLinkCta = ({ lender }) => {
  if (!lender.reviewLink) return null

  return (
    <a
      className={classNames("clickable", styles["review-link"])}
      href={lender.reviewLink}
      data-cta-type={REVIEW_CTA_TYPE}
      data-cy="review-link-cta"
      target="_blank"
      rel="noreferrer"
    >
      Read Full Review
    </a>
  )
}

ReviewLinkCta.propTypes = {
  lender: PropTypes.shape({
    reviewLink: PropTypes.string,
  }).isRequired,
}

const buildRow = (data, { hideBestFor, replaceReviewLink = false }) => {
  const { lender } = data

  return {
    ...data,
    key: lender.heading,
    lender: (
      <EntityCell
        imgAlt={lender.imgAlt}
        imgSrc={lender.imgSrc}
        text={lender.heading}
        subNodes={
          replaceReviewLink ? (
            <ReviewLinkCta lender={lender} />
          ) : (
            <LenderCTA
              buttonClassName={styles["cta-not-wide"]}
              textClassName={styles["cta-text-not-wide"]}
              hideBestFor={hideBestFor}
              ctaType={SUMMARY_CTA_TYPE}
              lender={lender}
            />
          )
        }
      />
    ),
    apr: (
      <>
        <span>{data.apr}</span>
        <Tooltip content="Depending on your creditworthiness and your business's financials" />
      </>
    ),
    nextSteps: (
      <LenderCTA
        hideBestFor={hideBestFor}
        ctaType={SUMMARY_CTA_TYPE}
        lender={lender}
      />
    ),
  }
}

const LendersTable = props => {
  const replaceReviewLink = useFeatureFlag(
    "replace-review-link-on-summary-table"
  )
  const { data, hideBestFor, stickyHeader } = props

  const finalData = data.map(lenderData =>
    buildRow(lenderData, { hideBestFor, replaceReviewLink })
  )

  const columns = [
    {
      id: "lender",
      heading: "Lender",
    },
    {
      id: "bestFor",
      heading: "Best for",
    },
    {
      id: "maxLoanAmount",
      heading: "Max loan amount",
    },
    {
      id: "credit",
      heading: "Min. credit score",
    },

    replaceReviewLink
      ? null
      : {
          id: "nextSteps",
          heading: "Next steps",
        },
  ].filter(i => i)

  return (
    <TableSimple
      className={classNames(styles.table, {
        [styles["hide-last-column"]]: !replaceReviewLink,
        [styles.stickyHeader]: stickyHeader,
      })}
      columns={hideBestFor ? columns.filter(x => x.id !== "bestFor") : columns}
      data={finalData}
    />
  )
}

LendersTable.propTypes = {
  data: PropTypes.array.isRequired,
  hideBestFor: PropTypes.bool,
  stickyHeader: PropTypes.bool,
}

LendersTable.defaultProps = {
  stickyHeader: false,
}

export default LendersTable
