const defaultOptions = { desc: false }

const sortByNumber = (sortBy, options) => tableData => {
  if (!sortBy) return tableData

  const { desc } = { ...defaultOptions, ...options }
  const getFirstNumber = /[0-9.,]+/gm
  const sortedTable = tableData.sort((firstItem, secondItem) => {
    const firstNumber = getFirstNumber
      .exec(firstItem[sortBy])?.[0]
      .replaceAll(",", "")
    getFirstNumber.lastIndex = 0 //manually reset regex

    const secondNumber = getFirstNumber
      .exec(secondItem[sortBy])?.[0]
      .replaceAll(",", "")
    getFirstNumber.lastIndex = 0 //manually reset regex

    return desc ? secondNumber - firstNumber : firstNumber - secondNumber
  })
  return sortedTable
}

export default sortByNumber
