import { usePageLocation } from "@src/hooks/usePageLocation"

const MOBILE_SUMMARY_TABLE_PAGES = [
  "/best/small-business/business-line-of-credit",
  "/best/small-business/llc-business-loans",
  "/best/small-business/equipment-financing-loans",
]

// true = enabled
// false = disabled
// null = doesn't specified
const useFeatureFlag = flagName => {
  const { pathname, search } = usePageLocation()

  if (search && search.indexOf(`${flagName}=true`) > -1) {
    return true
  }

  if (search && search.indexOf(`${flagName}=false`) > -1) {
    return false
  }

  if (flagName === "hide-recap-section") {
    return process.env.GATSBY_FF_HIDE_RECAP_SECTION === "true"
  }

  if (flagName === "display-business-loan-calculator") {
    return process.env.GATSBY_FF_DISPLAY_BUSINESS_LOANS_CALCULATOR === "true"
  }

  if (flagName === "tiled-banking-cards") {
    return process.env.GATSBY_FF_BANKING_ADD_TILED_PRODUCT_CARDS === "true"
  }

  if (flagName === "replace-review-link-on-summary-table") {
    if (process.env.GATSBY_FF_REVIEW_LINK_SUMMARY_TABLE !== "true") return false
    return [
      "/best/small-business/small-business-loans/compare-financing",
      "/small-business-loans",
    ].some(reviewLinkPath => pathname.startsWith(reviewLinkPath))
  }

  if (flagName === "replace-cta-component-on-loan-for-minorities") {
    if (
      process.env.GATSBY_TRUSTBUILDING_CTA_ON_LOANS_FOR_MINORITIES_PAGE !==
      "true"
    ) {
      return false
    }
    return pathname.startsWith(
      "/best/small-business/small-business-loans-for-minorities"
    )
  }

  if (flagName === "mobile-summary-table") {
    if (process.env.GATSBY_FF_MOBILE_SUMMARY_TABLE !== "true") {
      return false
    }
    return MOBILE_SUMMARY_TABLE_PAGES.some(allowListPage =>
      pathname.startsWith(allowListPage)
    )
  }

  if (flagName === "lending-ru-add-loan-process") {
    return process.env.GATSBY_FF_LENDING_RU_ADD_LOAN_PROCESS === "true"
  }

  if (flagName === "lending-ru-add-credit-score-signup-cta") {
    return (
      process.env.GATSBY_FF_LENDING_RU_ADD_CREDIT_SCORE_SIGNUP_CTA === "true"
    )
  }

  if (flagName === "lending-ru-add-simple-table") {
    return process.env.GATSBY_FF_LENDING_RU_ADD_SIMPLE_TABLE === "true"
  }

  if (flagName === "lending-ru-add-hero") {
    return process.env.GATSBY_FF_LENDING_RU_ADD_HERO === "true"
  }

  if (flagName === "lending-ru-add-next-steps") {
    return process.env.GATSBY_FF_LENDING_RU_ADD_NEXT_STEPS === "true"
  }

  if (flagName === "lending-ru-remove-product-card-loan-option-cta") {
    return (
      process.env.GATSBY_FF_LENDING_RU_REMOVE_PRODUCT_CARD_LOAN_OPTION_CTA ===
      "true"
    )
  }

  if (flagName === "lending-ru-add-trustpilot-banner") {
    return process.env.GATSBY_FF_LENDING_RU_ADD_TRUSTPILOT_BANNER === "true"
  }

  if (flagName === "lending-ru-replace-table-with-container-boxes") {
    return (
      process.env.GATSBY_FF_LENDING_RU_REPLACE_TABLE_WITH_CONTAINER_BOXES ===
      "true"
    )
  }

  if (flagName === "lending-ru-full-width-category") {
    return process.env.GATSBY_FF_LENDING_RU_FULL_WIDTH_CATEGORY === "true"
  }

  if (flagName === "add-tiled-product-cards") {
    if (process.env.GATSBY_FF_CC_ADD_TILED_PRODUCT_CARDS !== "true") {
      return false
    }
    return !pathname.startsWith("/best/credit-cards/small-business")
  }

  if (flagName === "add-promoted-offer") {
    return process.env.GATSBY_FF_CC_ADD_PROMOTED_OFFER === "true"
  }

  if (flagName === "banking-ru-add-promoted-offer") {
    return process.env.GATSBY_FF_BANKING_ADD_PROMOTED_OFFER === "true"
  }

  return null
}

export default useFeatureFlag
