import React from "react"
import PropTypes from "prop-types"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import LendersTableSection from "@src/components/roundup/LendersTableSection"
import TrustbuildingCTA from "@src/components/TrustbuildingCTA"
import { LocationVariantProvider } from "@src/hooks/useLocationVariant"
import DefaultSeparator from "./_component_default_separator"

const sections = [
  {
    categoryName: "Online lines of credit",
    marketplaceEntity: {
      id: "b9437360-3c53-11e9-9778-6772bac39338",
      status: "ACTIVE",
      image: {
        alt: "Bluevine - Line of credit",
        sourceMedium:
          "https://www.nerdwallet.com/cdn-cgi/image/quality=85/cdn/img/diy/smb/BluevineLogo.png",
      },
      displayName: "Bluevine - Line of credit",
      maxLoanAmount: 250000,
      reviewLink: "https://www.nerdwallet.com/blog/small-business/bluevine/",
      cta: {
        link: "https://www.nerdwallet.com/redirect/b9437360-3c53-11e9-9778-6772bac39338?mvt=MTY2MzE5MTA0NDMzODE3MTc4ODpmYzJiYWUzNGQ2MjFiMTViNTBmZTlmNmUwZDkwZTIxMTA2NTljYTVmMjU3ZmY0OGItOGUxOC00MWE0LWMxMTctNWJiNzRmZTRmOTU0OmExNTAyNWE2OTdiY2YwNzEzYTc0ZWRkM2FjMzQyNmNkYjNhNzliOTRlMWRlYTg4NmNhYjAyMjliYzIxZGFiMDA%3D",
        text: "See Your Loan Options",
        subtext: null,
      },
      institution: {
        id: "e239fd2e-3c4f-11e9-9778-337bb879280c",
        name: "Bluevine",
      },
      product: {
        id: "fd5e6676-3c4f-11e9-9778-5f440b1d8028",
      },
      productType: "small_medium_business_loans",
      placementDrivers: [
        {
          id: "estApr",
          label: "Est. APR",
          value: "15.00-78.00%",
          tooltip: {
            type: "Container",
            version: "1.0.0",
            props: {
              children: {
                type: "Type",
                version: "1.0.0",
                props: {
                  children:
                    "Depending on your creditworthiness and your business's financials",
                },
              },
            },
          },
        },
        {
          id: "minCreditScore",
          label: "Min. credit score",
          value: "720",
          tooltip: null,
        },
      ],
      productLabel: "May fund quickly",
    },
  },
  {
    categoryName: "Bank small business loans",
    marketplaceEntity: {
      id: "bffef000-4d42-11ec-a252-c37c34e48f4b",
      status: "ACTIVE",
      image: {
        alt: "TAB Bank - Term loan",
        sourceMedium:
          "https://www.nerdwallet.com/cdn-cgi/image/quality=85/cdn/img/diy/smb/tab-bank-logo.jpeg",
      },
      displayName: "TAB Bank - Term loan",
      maxLoanAmount: 100000,
      reviewLink: null,
      cta: {
        link: "https://www.nerdwallet.com/redirect/bffef000-4d42-11ec-a252-c37c34e48f4b?mvt=MTY2MzE5MTA0NDQxNjgzMTM4ODoxNzRhMTI4YjNiMDk1ZWE1MDkzOTA4YjYxZGViMjZlYWVjMmMwZjY5OTc3ZDMzMmEtZTQ4NC00Njc4LWM2YTUtYjA4Y2QwNjhjMzBhOmM4MmY4ZDc4ZGM2ZDE5MmMxNTk3MTM1NDFkODk2ODhjZDRmNmIyZmFkMmQyNjJiMzlmM2I3YzFhMTQyYzU1Mzc%3D",
        text: "See Your Loan Options",
        subtext: null,
      },
      institution: {
        id: "a144aebc-4d41-11ec-8702-b3a59a08aac5",
        name: "TAB Bank",
      },
      product: {
        id: "c3b8c9d8-4d41-11ec-9e55-bf20882a5f62",
      },
      productType: "small_medium_business_loans",
      placementDrivers: [
        {
          id: "estApr",
          label: "Est. APR",
          value: "8.99-35.99%",
          tooltip: {
            type: "Container",
            version: "1.0.0",
            props: {
              children: {
                type: "Type",
                version: "1.0.0",
                props: {
                  children:
                    "Depending on your creditworthiness and your business's financials",
                },
              },
            },
          },
        },
        {
          id: "minCreditScore",
          label: "Min. credit score",
          value: "690",
          tooltip: null,
        },
      ],
      productLabel: null,
    },
  },
  {
    categoryName: "Online term loans",
    marketplaceEntity: {
      id: "b6588208-3c53-11e9-9778-df49da3b8115",
      status: "ACTIVE",
      image: {
        alt: "Funding Circle - Online term loan",
        sourceMedium:
          "https://www.nerdwallet.com/cdn-cgi/image/quality=85/cdn/img/diy/smb/fundingcircle.png",
      },
      displayName: "Funding Circle - Online term loan",
      maxLoanAmount: 250000,
      reviewLink: "https://www.nerdwallet.com/blog/funding-circle/",
      cta: {
        link: "https://www.nerdwallet.com/redirect/b6588208-3c53-11e9-9778-df49da3b8115?mvt=MTY2MzE5MTA0MjE5NjExNTQ4NDpmYWM3MzU0YTVlYTQ5YmNlZTUxODc5ZmVjMTcxMmRiYTQ2NmMwYWFmYzQ3MmEyN2EtOGI5Yy00MDliLWMwOWUtOTVmNzJmY2M5OWM0OmYzNmIxNjE5YzljMDQ5MmI0NTk2MmI1MGFlYzc0ODhkZjE0MzgyNWE4ZmU5ZTUwYTQ5MWNkYzMxNjE1MzBhNzc%3D",
        text: "See Your Loan Options",
        subtext: null,
      },
      institution: {
        id: "e1d512c4-3c4f-11e9-9778-07b81a99fbda",
        name: "Funding Circle",
      },
      product: {
        id: "f9f304ba-3c4f-11e9-9778-7b336161ecef",
      },
      productType: "small_medium_business_loans",
      placementDrivers: [
        {
          id: "estApr",
          label: "Est. APR",
          value: "10.64-31.85%",
          tooltip: {
            type: "Container",
            version: "1.0.0",
            props: {
              children: {
                type: "Type",
                version: "1.0.0",
                props: {
                  children:
                    "Depending on your creditworthiness and your business's financials",
                },
              },
            },
          },
        },
        {
          id: "minCreditScore",
          label: "Min. credit score",
          value: "630",
          tooltip: null,
        },
      ],
      productLabel: "May fund quickly",
    },
  },
  {
    categoryName: "Online term loans",
    marketplaceEntity: {
      id: "b6ec0da2-3c53-11e9-9778-6f31368cc98d",
      status: "ACTIVE",
      image: {
        alt: "OnDeck - Online term loan",
        sourceMedium:
          "https://www.nerdwallet.com/cdn-cgi/image/quality=85/cdn/img/diy/smb/ondeck.png",
      },
      displayName: "OnDeck - Online term loan",
      maxLoanAmount: 250000,
      reviewLink:
        "https://www.nerdwallet.com/blog/small-business/ondeck-small-business-loan-review-easy-money-cost/",
      cta: {
        link: "https://www.nerdwallet.com/redirect/b6ec0da2-3c53-11e9-9778-6f31368cc98d?mvt=MTY2MzE5MTA0NDM3ODM2NjI2Nzo1ZTk0NWE0MjJjOGE2ODBhODNkYTg3MDNiYWNkZDc5NzJhNjJmN2Q3ZmM4ODg5NGUtMzQ5Ni00YmExLWNiM2YtZTE5MmQxNjdkMTliOjA2Y2NjYWI5YjA4ZmNjNDM4NTM5NjRmZjNmMTRkMjdhNzE3ODMyZGMwNzhlZDMxNjRhZmYzYjg2ZjNmZjExYTE%3D",
        text: "See Your Loan Options",
        subtext: null,
      },
      institution: {
        id: "e2715b16-3c4f-11e9-9778-6375996d66bd",
        name: "OnDeck",
      },
      product: {
        id: "fa848494-3c4f-11e9-9778-4f44511d5c66",
      },
      productType: "small_medium_business_loans",
      placementDrivers: [
        {
          id: "estApr",
          label: "Est. APR",
          value: "29.90-97.30%",
          tooltip: {
            type: "Container",
            version: "1.0.0",
            props: {
              children: {
                type: "Type",
                version: "1.0.0",
                props: {
                  children:
                    "Based on loans originated in the half-year ending March 31, 2022",
                },
              },
            },
          },
        },
        {
          id: "minCreditScore",
          label: "Min. credit score",
          value: "629",
          tooltip: null,
        },
      ],
      productLabel: "May fund quickly",
    },
  },
]

const FilterableLendersTablePage = ({ location }) => {
  const title = "Credit score filter - Test Page"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "CREDIT_SCORE",
  }

  return (
    <LocationVariantProvider location={location} assignments={{}}>
      <Page
        title={title}
        header={<Header />}
        headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
        footer={<Footer />}
      >
        <TrustbuildingCTA headerSize="large" />
        <LendersTableSection
          filter
          sort
          sections={sections}
          hideBestFor={true}
          productDescription="small business loans"
        />
        <DefaultSeparator />
      </Page>
    </LocationVariantProvider>
  )
}

FilterableLendersTablePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default FilterableLendersTablePage
