import React, { useState } from "react"
import PropTypes from "prop-types"

import { Box } from "@nerdwallet/currency"

import Dropdown from "@src/components/Dropdown"
import LendersTable from "@src/components/LendersTable"
import SummaryTableTitle from "@src/components/SummaryTableTitle"
import MiniTable from "@src/components/MiniTable"
import TableFeatures from "@src/components/roundup/TableFeatures"

import sortByNumber from "@src/components/roundup/sortByNumber"
import {
  formatCurrency,
  getApplyOrRedirectUrl,
  getLendingBestFor,
  isSectionActive,
} from "@src/lib/helpers"
import { findInPlacementDrivers } from "@src/lib/drivers"
import { FUNDERA_BY_NERDWALLET, SUMMARY_CTA_TYPE } from "@src/lib/constants"

const FACTS = ["estApr", "minCreditScore"]

const filterDropdownOptions = [
  { key: "excellent", value: "Excellent (720-850)" },
  { key: "good", value: "Good (690-719)" },
  { key: "fair", value: "Fair (630-689)" },
  { key: "bad", value: "Bad (350-629)" },
]

const sortDropdownOptions = [
  {
    key: "maxLoanAmount",
    value: "Max loan amount",
    sortingOptions: { desc: true },
  },
  { key: "credit", value: "Min. credit score" },
]

const getSortingOptions = sortBy => {
  if (!sortBy) return

  return sortDropdownOptions.find(({ key }) => key === sortBy).sortingOptions
}

const filterByCreditScore = (data, filterBy) => {
  if (!filterBy) return data

  const minCredit = {
    excellent: 850,
    good: 719,
    fair: 689,
    bad: 629,
  }[filterBy]

  return data.filter(lender => parseInt(lender.credit) <= minCredit)
}

const extractApr = lender => {
  return lender.heading === "Top SBA Lenders"
    ? "Starting at 6%"
    : lender.quickFacts[0]["value"]
}

const LendersTableSection = ({
  sections,
  sort,
  productDescription,
  filter,
}) => {
  const [sortBy, setSortBy] = useState("")
  const [filterBy, setFilterBy] = useState("")

  const lenders = sections.filter(isSectionActive).map(section => {
    const { marketplaceEntity } = section
    return {
      marketplaceEntity: {
        id: marketplaceEntity.id,
        product: marketplaceEntity.product,
        institution: marketplaceEntity.institution,
        productType: marketplaceEntity.productType,
      },
      type: section.categoryName,
      heading: marketplaceEntity.displayName,
      imgAlt: marketplaceEntity.image.alt,
      imgSrc: marketplaceEntity.image.sourceMedium,
      quickFacts: FACTS.map(id => {
        const placementDriver = findInPlacementDrivers(
          marketplaceEntity.placementDrivers,
          id
        )
        const value = placementDriver?.value
        return {
          label: placementDriver?.label,
          value,
        }
      }),
      reviewLink: marketplaceEntity.reviewLink,
      applyText: marketplaceEntity.cta.text || null,
      applyUrl: getApplyOrRedirectUrl(section),
      buttonAnnotation: marketplaceEntity.cta.subtext || FUNDERA_BY_NERDWALLET,
      maxFundingFormatted: formatCurrency(marketplaceEntity.maxLoanAmount),
    }
  })

  const mappedData = lenders.map(lender => ({
    lender,
    bestFor: getLendingBestFor(lender),
    apr: extractApr(lender),
    maxLoanAmount: lender.maxFundingFormatted,
    credit:
      lender.heading === "Top SBA Lenders"
        ? "620"
        : lender.quickFacts[1]["value"],
  }))
  const filteredData = filterByCreditScore(mappedData, filterBy)
  const sortingFunction = sortByNumber(sortBy, getSortingOptions(sortBy))
  const data = sortingFunction(filteredData)

  const hideBestFor = sections.some(section => !section.categoryName)
  const enableCreditFilter = filter && lenders.length >= 4

  const tableWrapperDisplay = {
    mobile: "none",
    desktop: "block",
  }

  return (
    <>
      <Box display={tableWrapperDisplay}>
        <SummaryTableTitle
          rowCount={data.length}
          productDescription={productDescription}
        />
        <TableFeatures
          sort={
            sort && (
              <Dropdown
                smallLegend
                boldLegend={false}
                header="Sort by"
                options={sortDropdownOptions}
                placeholder="Make selection"
                setUpdateAction={setSortBy}
                rightAligned={enableCreditFilter}
              />
            )
          }
          filter={
            enableCreditFilter && (
              <Dropdown
                analyticsTag="Credit Score Select"
                smallLegend
                boldLegend={false}
                header="Credit score"
                options={filterDropdownOptions}
                placeholder="All"
                setUpdateAction={setFilterBy}
              />
            )
          }
        />
        <LendersTable
          data={data}
          hideBestFor={hideBestFor}
          sort={sort}
          stickyHeader
        />
      </Box>

      <Box display={{ mobile: "block", desktop: "none" }}>
        <SummaryTableTitle
          rowCount={lenders.length}
          productDescription={productDescription}
        />
        <Box marginTop="3-half">
          <MiniTable
            rows={[
              {
                id: "name",
                formatter: "accent",
              },
              {
                id: "maxLoanAmount",
                heading: "Max Amount",
              },
              {
                id: "credit",
                heading: "Min. Credit Score",
              },
              {
                id: "cta",
                formatter: "cta",
              },
            ]}
            data={lenders.map(lender => ({
              ...lender,
              id: lender.marketplaceEntity.id,
              name: lender.marketplaceEntity.institution.name,
              category: getLendingBestFor(lender),
              credit:
                lender.heading === "Top SBA Lenders"
                  ? "620"
                  : lender.quickFacts[1]["value"],
              cta: {
                cta: {
                  text: lender.applyText,
                  link: lender.applyUrl,
                  type: SUMMARY_CTA_TYPE,
                },
                marketplaceEntity: lender.marketplaceEntity,
              },
              maxLoanAmount: lender.maxFundingFormatted,
            }))}
          />
        </Box>
      </Box>
    </>
  )
}

LendersTableSection.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      marketplaceEntity: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  sort: PropTypes.bool.isRequired,
  productDescription: PropTypes.string.isRequired,
  filter: PropTypes.bool,
}

LendersTableSection.defaultProps = {
  filter: false,
}

export default LendersTableSection
