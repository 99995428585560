import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Accent } from "@nerdwallet/currency"
import { slugify } from "@src/lib/helpers"
import Type from "@nerdwallet/react-typography"
import TrackedCTA from "@src/components/TrackedCTA"
import AnchorLink from "@src/components/AnchorLink"
import { StarRatingCell } from "@src/components/Table"

import styles from "./MiniTable.module.less"

const formatters = {
  accent: props => (
    <div className={classNames(styles.accentContainer, styles.spaced)}>
      <Accent>{props.value}</Accent>
    </div>
  ),
  button: props => (
    <div className={styles.buttonContainer}>
      <AnchorLink
        className={styles.buttonText}
        target={slugify(props.value.linkTarget)}
        ctaType={props.value.ctaType}
      >
        <div className={classNames(styles.customPadding, styles.button)}>
          View Details
        </div>
      </AnchorLink>
    </div>
  ),
  cta: props => {
    const text = (
      <span className={styles.buttonText}>{props.value.cta.text}</span>
    )
    return (
      props.value.cta && (
        <div className={styles.buttonContainer}>
          <TrackedCTA
            buttonClassName={styles.button}
            cta={{ ...props.value.cta, text }}
            href={props.value.cta.link}
            ctaType={props.value.cta.type}
            id={props.value.marketplaceEntity.id}
            productType={props.value.marketplaceEntity.productType}
            product={props.value.marketplaceEntity.product}
            institution={props.value.marketplaceEntity.institution}
            block
          />
        </div>
      )
    )
  },
  rating: props => (
    <div className={classNames(styles.rowContainer, styles.spaced)}>
      <Type className={styles.rowCell}>{props.heading}</Type>
      <Type className={styles.rowCell}>
        <StarRatingCell
          rating={props.value.rating.rating}
          link={props.value.rating.link}
        />
      </Type>
    </div>
  ),
  row: props => (
    <div className={classNames(styles.rowContainer, styles.spaced)}>
      <Type className={styles.rowCell}>{props.heading}</Type>
      <Type className={styles.rowCell}>{props.value}</Type>
    </div>
  ),
}

const InnerTable = ({ children }) => {
  return <div className={styles.table}>{children}</div>
}

InnerTable.propTypes = {
  children: PropTypes.node.isRequired,
}

const MiniTable = ({ rows, data }) => {
  const grouped = data.reduce((acc, entry) => {
    if (!acc[entry.category]) {
      acc[entry.category] = []
    }
    acc[entry.category].push(entry)
    return acc
  }, {})
  return (
    <div>
      {Object.keys(grouped).map(category => {
        return (
          <div key={category}>
            <div className={styles.heading}>
              <Type size={2}>{category}</Type>
            </div>
            {grouped[category].map(entry => {
              return (
                <InnerTable key={entry.id}>
                  {rows
                    .filter(row => entry[row.id] !== null)
                    .map(row => {
                      const Component =
                        formatters[row.formatter] || formatters.row
                      return (
                        <Component
                          key={row.id}
                          heading={row.heading}
                          value={entry[row.id]}
                        />
                      )
                    })}
                </InnerTable>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

MiniTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default MiniTable
