import React from "react"
import PropTypes from "prop-types"
import Button from "@src/components/Button"
import classNames from "classnames"
import { Text } from "@nerdwallet/react-typography"
import { SIGN_UP_TEXT, FUNDERA_BY_NERDWALLET } from "@src/lib/constants"
import { usePageLocation } from "@src/hooks/usePageLocation"
import { attachQueryString } from "@src/lib/helpers"

import {
  buildElementPositionAttributes,
  buildProductShoppingAttributes,
} from "@src/lib/analytics_payloads"
import { withImpression } from "@src/lib/impression"

import styles from "./LenderCTA.module.less"

const TrackedButton = withImpression(
  ({ marketplaceEntity, ...props }) => <Button {...props} />,
  {
    eventType: "product_impression",
    payload: ({ props }) => ({
      ...buildElementPositionAttributes({
        sectionName: props["data-cta-type"],
      }),
      ...buildProductShoppingAttributes({
        marketplace_product_type: props.marketplaceEntity.productType,
        marketplace_institution_id: props.marketplaceEntity.institution.id,
        marketplace_product_id: props.marketplaceEntity.product.id,
        marketplace_offer_id: props.marketplaceEntity.id,
      }),
      entity_name: props["data-cta-type"],
    }),
  },
  {
    propWithImpression: "href",
  }
)

const LenderCTA = ({
  hideBestFor,
  lender,
  ctaType,
  buttonClassName,
  textClassName,
}) => {
  const applyText = lender.applyText || SIGN_UP_TEXT
  const annotationText = lender.buttonAnnotation || FUNDERA_BY_NERDWALLET
  const { pathname } = usePageLocation()
  const href = attachQueryString(lender.applyUrl, {
    source_url: `https://www.nerdwallet.com${pathname}`, // TODO: Better define base for absolute URL
    cta_type: ctaType,
  })

  return (
    <>
      <TrackedButton
        marketplaceEntity={lender.marketplaceEntity}
        primary
        className={classNames(styles.button, buttonClassName, "clickable")}
        href={href}
        data-cta-type={ctaType}
        rel="sponsored"
        target="_blank"
        aria-label={applyText || "Apply Button"}
      >
        {applyText}
      </TrackedButton>
      <Text
        small
        color="neutral-darker"
        className={classNames(textClassName, styles.text)}
        style={{ textAlign: hideBestFor ? "left" : null }}
      >
        {" "}
        {annotationText}
      </Text>
    </>
  )
}

LenderCTA.propTypes = {
  hideBestFor: PropTypes.bool.isRequired,
  lender: PropTypes.shape({
    productType: PropTypes.string.isRequired,
    institutionId: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    offerId: PropTypes.string.isRequired,
    applyText: PropTypes.string,
    applyUrl: PropTypes.string.isRequired,
    buttonAnnotation: PropTypes.node.isRequired,
    marketplaceEntity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      productType: PropTypes.string.isRequired,
      institution: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      product: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  ctaType: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string,
  textClassName: PropTypes.string,
}

export default LenderCTA
